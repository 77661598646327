import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Accordion,
  Card,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";

const VideoPlayer = ({
  videoUrl,
  timeStampCategories,
  useLandscape,
  onVideoEnd,
  onVideoTimeUpdate,
  onCategoryClick,
}) => {
  const videoRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [activeIndex, setActiveIndex] = useState({
    categoryIndex: null,
    detailIndex: null,
  });
  const [accordionActiveKey, setAccordionActiveKey] = useState(null);
  const [userSelectedCategoryIndex, setUserSelectedCategoryIndex] =
    useState(null);

  const [isEndSlide, setIsEndSlide] = useState(false);

  const formatText = (text) => {
    var output = text;

    if (!text) return output;

    // replace any html or script tags
    output = output.replace(/<.*?>/g, "");

    // Add line breaks and bold text
    output = output
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

    return output;
  };

  const jumpTo = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time;
      videoRef.current.play();
    }
  };

  const handleAccordionToggle = (categoryIndex) => {
    const accordionActiveKeyAsInt = parseInt(accordionActiveKey);
    const newCategoryIndex =
      accordionActiveKeyAsInt === categoryIndex ? null : categoryIndex;

    setAccordionActiveKey(`${newCategoryIndex}`);
    setUserSelectedCategoryIndex(newCategoryIndex);

    if (onCategoryClick) onCategoryClick(categoryIndex);

    // Jump to the first detail of the selected category
    if (newCategoryIndex !== null) {
      jumpTo(
        timeStampCategories[newCategoryIndex].timeStampDetails[0].startTime
      );
    }

    // Reset setUserSelectedCategoryIndex after 5 seconds
    setTimeout(() => {
      setUserSelectedCategoryIndex(null);
    }, 5000);
  };

  useEffect(() => {
    const handleTimeUpdate = () => {
      if (videoRef.current) {
        const current = videoRef.current.currentTime;
        setCurrentTime(Math.round(current));

        // Determine which TimeStampDetail is currently active
        let foundActive = false;
        timeStampCategories.forEach((category, categoryIndex) => {
          category.timeStampDetails.forEach((detail, detailIndex) => {
            if (
              current >= detail.startTime &&
              current <= detail.endTime &&
              !foundActive
            ) {
              foundActive = true;
              if (
                categoryIndex !== activeIndex.categoryIndex ||
                detailIndex !== activeIndex.detailIndex
              )
                setActiveIndex({
                  categoryIndex,
                  detailIndex,
                });
            }
          });
        });
        if (!foundActive) {
          setActiveIndex({ categoryIndex: null, detailIndex: null });
        }
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      if (onVideoTimeUpdate)
        videoElement.addEventListener("timeupdate", onVideoTimeUpdate);
      if (onVideoEnd) videoElement.addEventListener("ended", onVideoEnd);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        if (onVideoTimeUpdate)
          videoElement.addEventListener("timeupdate", onVideoTimeUpdate);
        if (onVideoEnd) videoElement.removeEventListener("ended", onVideoEnd);
      }
    };
  }, [timeStampCategories]);

  useEffect(() => {
    if (activeIndex.categoryIndex !== null) {
      setAccordionActiveKey(
        null !== userSelectedCategoryIndex
          ? `${userSelectedCategoryIndex}`
          : `${activeIndex.categoryIndex}`
      );

      // Set if the end slide is active
      if (activeIndex.categoryIndex === timeStampCategories.length - 1) {
        if (
          activeIndex.detailIndex ===
          timeStampCategories[activeIndex.categoryIndex].timeStampDetails
            .length -
            1
        ) {
          setIsEndSlide(true);
        } else {
          setIsEndSlide(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <Container fluid className="video-player-container">
      <Row>
        {/* Hidden on small sizes and landscape */}
        {!useLandscape && (
          <Col xl={4} className={"d-none d-xl-block"}>
            <div
              style={{
                height: "100%",
                backgroundColor: "var(--blue)",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "19px",
                textAlign: isEndSlide ? "left" : "center",
                color: "white",
                fontSize: "30px",
              }}
            >
              {activeIndex &&
              activeIndex.categoryIndex !== null &&
              activeIndex.detailIndex !== null ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatText(
                      timeStampCategories[activeIndex.categoryIndex]
                        .timeStampDetails[activeIndex.detailIndex].slideText
                    ),
                  }}
                ></div>
              ) : null}
            </div>
          </Col>
        )}
        <Col
          xs={12}
          lg={6}
          xl={useLandscape ? 8 : 4}
          className="video-container"
          style={{
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            marginTop: "-10px", // Offset the video white space
          }}
        >
          <video
            ref={videoRef}
            width="95%"
            height="auto"
            controls
            autoPlay
            playsInline
            style={{ borderRadius: "10px" }}
          >
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Col>
        <Col
          lg={6}
          xl={4}
          className="d-none d-lg-block"
          style={{ width: "100%" }}
        >
          <Accordion activeKey={accordionActiveKey}>
            {timeStampCategories.map((category, categoryIndex) => (
              <Card
                key={categoryIndex}
                style={{ borderBottom: "1px solid white" }}
              >
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey={`${categoryIndex}`}
                  onClick={() => handleAccordionToggle(categoryIndex)}
                  style={{
                    cursor: "pointer",
                    paddingBottom: "15px",
                    fontSize: "24px",
                    fontWeight: "400",
                    fontStyle: "italic",
                    backgroundColor:
                      activeIndex.categoryIndex === categoryIndex
                        ? "var(--blue)"
                        : "rgba(128,128,128,0.2)",
                    color:
                      activeIndex.categoryIndex === categoryIndex
                        ? "white"
                        : "black",
                  }}
                >
                  {category.categoryTitle}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={`${categoryIndex}`}>
                  <Card.Body>
                    <h4
                      style={{
                        paddingBottom: "15px",
                        fontSize: "20px",
                        fontWeight: "300",
                      }}
                    >
                      {category.categoryDescription}
                    </h4>
                    <ListGroup>
                      {category.timeStampDetails.map((detail, detailIndex) => (
                        <ListGroupItem
                          key={detailIndex}
                          onClick={() => jumpTo(detail.startTime + 1)}
                          active={
                            activeIndex.categoryIndex === categoryIndex &&
                            activeIndex.detailIndex === detailIndex
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <h5>{detail.title}</h5>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: formatText(detail.description),
                            }}
                          ></p>
                          {/* <p
                            style={{
                              color: "rgba(128,128,128,0.3)",
                              fontWeight: "100",
                            }}
                          >
                            Start Time: {detail.startTime}s, End Time:{" "}
                            {detail.endTime}s
                          </p> */}
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default VideoPlayer;

// Usage example:
// <VideoPlayer
//   videoUrl="https://example.com/video.mp4"
//   timeStampCategories={[
//     {
//       categoryTitle: "Chapter 1",
//       timeStampDetails: [
//         { title: "Introduction", startTime: 0, endTime: 30, description: "This is the introduction." },
//         { title: "Topic A", startTime: 31, endTime: 60, description: "Details about Topic A." }
//       ]
//     },
//     {
//       categoryTitle: "Chapter 2",
//       timeStampDetails: [
//         { title: "Topic B", startTime: 61, endTime: 90, description: "Details about Topic B." },
//         { title: "Topic C", startTime: 91, endTime: 120, description: "Details about Topic C." }
//       ]
//     }
//   ]}
// />
