import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import ResumeDetails from "../Modules/Views/Shared/ResumeDetails";
// employer views
import Dashboard from "../Modules/Views/Employer/Dashboard";
import PostJob from "../Modules/Views/Employer/PostJob";
import Resumes from "../Modules/Views/Employer/Resumes";

import EditJob from "../Modules/Views/Employer/EditJob";
import EmployersJobDetails from "../Modules/Views/Employer/EmployerJobDetails";
import PublicResumeDetails from "../Modules/Views/Employer/PublicResumeDetails";

// seekers module
import Seeker from "../Modules/Views/Seeker/Dashboard";
import Network from "../Modules/Views/Seeker/Network";
import Jobs from "../Modules/Views/Seeker/Jobs";
import AppliedJobs from "../Modules/Views/Seeker/AppliedJobs";
import ResumeUpload from "../Modules/Views/Seeker/ResumeUpload";
import Messages from "../Modules/Views/Shared/Messages";
import ActivitiesList from "../Modules/Views/Shared/ActivitiesList";
import SeekersConnections from "../Modules/Views/Seeker/SeekersConnections";
import SeekersJobDetails from "../Modules/Views/Seeker/SeekersJobDetails";
import JINTemplate from "../Modules/Views/Seeker/JINTemplate";
import SponsorshipRequest from "../Modules/Views/Seeker/SponsorshipRequest";

// support views
import SupportDashboard from "../Modules/Views/Support/SupportDashboard";
import SupportConnections from "../Modules/Views/Support/SupportConnections";
import ConnectionDetails from "../Modules/Views/Support/ConnectionDetails";
import Sponsership from "../Modules/Views/Support/Sponsership";
import SponsershipDetails from "../Modules/Views/Support/SponsershipDetails";
import SupportJobDetails from "../Modules/Views/Support/SupportJobDetails";
import SupportJobList from "../Modules/Views/Support/SupportJobList";
import JobsList from "../Modules/Views/Employer/JobsList";
import SavedLaterJobsList from "../Modules/Views/Employer/SavedLaterJobsList";
//import SupportResumeDetails from "../Modules/Views/Support/SupportResumeDetails";

//groupleader

import GroupLeaderDashboard from "../Modules/Views/GroupLeader/GroupLeaderDashboard";
import JobSeekers from "../Modules/Views/GroupLeader/JobSeekers";
import SupportTeamMembers from "../Modules/Views/GroupLeader/SupportTeamMembers";
import Organization from "../Modules/Views/GroupLeader/Organization";
// import JobSeekersDetails from "../Modules/Views/GroupLeader/JobSeekersDetails";
// import SupportTeamMembersDetails from "../Modules/Views/GroupLeader/SupportTeamMembersDetails";
import GroupLeaderJobs from "../Modules/Views/GroupLeader/GroupLeaderJobs";

//Account
import ChangePassword from "../Modules/Views/Account/ChangePassword";
import AccountInformation from "../Modules/Views/Account/AccountInformation";
import Home from "../Modules/Views/Account/Home";
import Register from "../Modules/Views/Account/Register";
import Registerd from "../Modules/Views/Account/Registerd";
import ForgotPassword from "../Modules/Views/Account/ForgotPassword";
import TermOfUse from "../Modules/Views/Account/TermOfUse";
import GroupLeaderJobDetails from "../Modules/Views/GroupLeader/GroupLeaderJobDetails";
import ReportAbuse from "../Modules/Views/Shared/ReportAbuse";
import ReportAbuseListing from "../Modules/Views/GroupLeader/ReportAbuseListing";
import { Roles } from "../Utilities/Helper";

import JobSeekersDetails from "../Modules/Views/Shared/JobSeekersDetails";
import SupportTeamMembersDetails from "../Modules/Views/Shared/SupportTeamMembersDetails";
import EmployerDetails from "../Modules/Views/Shared/EmployerDetails";
import GroupLeaderDetails from "../Modules/Views/Shared/GroupLeaderDetails";

import AdminDashboard from "../Modules/Views/Admin/AdminDashboard";
import AdminManageUsers from "../Modules/Views/Admin/AdminManageUsers";
import AdminReportAbuse from "../Modules/Views/Admin/AdminReportAbuse";
import AdminDemoSetup from "../Modules/Views/Admin/AdminDemoSetup";
import { AdminDemoSlideshow } from "../Modules/Views/Admin/AdminDemoSlideshow";
import { AdminDemoSave } from "../Modules/Views/Admin/AdminDemoSave";
import { AdminDemoRestore } from "../Modules/Views/Admin/AdminDemoRestore";
import { AdminDemoRemove } from "../Modules/Views/Admin/AdminDemoRemove";
import { AdminDemoCreate } from "../Modules/Views/Admin/AdminDemoCreate";
import AdminGroupCategories from "../Modules/Views/Admin/AdminGroupCategories";

import GroupListingForSTM from "../Modules/Views/Shared/GroupListingForSTM";
import GroupListingForJS from "../Modules/Views/Shared/GroupListingForJS";

import Notifications from "../Components/Notifications";

import JINTemplatePrivate from "../Modules/Views/Seeker/JINTemplatePrivate";

import ShowVideo from "../Modules/Views/DemoVideo/ShowVideo";

import { getData } from "../Utilities/StorageHelper";

export const isAuth = () => {
  const auth = getData("token");
  return auth;
};

const PrivateRoute = ({ component: Component, ...params }) => (
  <Route
    {...params}
    render={(props) =>
      isAuth() ? (
        <Route component={Component} {...props} />
      ) : (
        <Redirect
          from={props.location?.pathname}
          state={{ from: props.location?.pathname }}
          to={`${process.env.PUBLIC_URL}/home?redirectTo=${props.location?.pathname}`}
        />
      )
    }
  />
);

const PrivateSTMRoute = ({ component: Component, ...params }) => (
  <Route
    {...params}
    render={(props) =>
      getData("role") === "Support Team Member" ? (
        <Route component={Component} {...props} />
      ) : (
        <Redirect
          from={props.location?.pathname}
          state={{ from: props.location?.pathname }}
          to={`${process.env.PUBLIC_URL}/home?redirectTo=${props.location?.pathname}`}
        />
      )
    }
  />
);

const PrivateAGLRoute = ({ component: Component, ...params }) => (
  <Route
    {...params}
    render={(props) =>
      getData("role") === "Group Leader" ? (
        <Route component={Component} {...props} />
      ) : (
        <Redirect
          from={props.location?.pathname}
          state={{ from: props.location?.pathname }}
          to={`${process.env.PUBLIC_URL}/home?redirectTo=${props.location?.pathname}`}
        />
      )
    }
  />
);

const PrivateEmployerRoute = ({ component: Component, ...params }) => (
  <Route
    {...params}
    render={(props) =>
      getData("role") === "Employer" ? (
        <Route component={Component} {...props} />
      ) : (
        <Redirect
          from={props.location?.pathname}
          state={{ from: props.location?.pathname }}
          to={`${process.env.PUBLIC_URL}/home?redirectTo=${props.location?.pathname}`}
        />
      )
    }
  />
);

const PrivateJobSeekerRoute = ({ component: Component, ...params }) => (
  <Route
    {...params}
    render={(props) =>
      getData("role") === "Job Seeker" ? (
        <Route component={Component} {...props} />
      ) : (
        <Redirect
          from={props.location?.pathname}
          state={{ from: props.location?.pathname }}
          to={`${process.env.PUBLIC_URL}/home?redirectTo=${props.location?.pathname}`}
        />
      )
    }
  />
);

const PrivateNetConnRoute = ({ component: Component, ...params }) => (
  <Route
    {...params}
    render={(props) =>
      getData("role") === Roles.jobSeeker ? (
        // && getData("count") > jobseekerStmCount
        <Route component={Component} {...props} />
      ) : (
        <Redirect
          from={props.location?.pathname}
          state={{ from: props.location?.pathname }}
          to={`${process.env.PUBLIC_URL}/home?redirectTo=${props.location?.pathname}`}
        />
      )
    }
  />
);

const PrivateAdminRoute = ({ component: Component, ...params }) => (
  <Route
    {...params}
    render={(props) =>
      getData("role") === Roles.admin ? (
        <Route component={Component} {...props} />
      ) : (
        <Redirect
          from={props.location?.pathname}
          state={{ from: props.location?.pathname }}
          to={`${process.env.PUBLIC_URL}/home?redirectTo=${props.location?.pathname}`}
        />
      )
    }
  />
);

class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`}>
          <Redirect to={`${process.env.PUBLIC_URL}/home`} />
        </Route>
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/dashboard`}
          component={Dashboard}
          // imagetoggle={this.props.imagetoggle}
        />
        {/* Admin Routes */}
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/dashboard`}
          component={AdminDashboard}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/manageusers`}
          component={AdminManageUsers}
        />{" "}
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/jobseekersdetails/:jobSeekerId`}
          component={JobSeekersDetails}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/jobseekersdetails/`}
          component={AdminDashboard}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/supportteammembersdetails/:supportTeamMemberId`}
          component={SupportTeamMembersDetails}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/supportteammembersdetails/`}
          component={AdminDashboard}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/employerdetails/:employerId`}
          component={EmployerDetails}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/employerdetails/`}
          component={AdminDashboard}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/groupleaderdetails/:groupleaderId`}
          component={GroupLeaderDetails}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/groupleaderdetails/`}
          component={AdminDashboard}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/activitieslist`}
          component={ActivitiesList}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/reportabuse`}
          component={AdminReportAbuse}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/supportteammembers`}
          component={GroupListingForSTM}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/jobseekers`}
          component={GroupListingForJS}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/groupcategories`}
          component={AdminGroupCategories}
        />
        {/* End Admin Routes | Demo Tools */}
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/demotools/save/:step`}
          component={AdminDemoSave}
        />
        <PrivateAdminRoute
          path={`/admin/demotools/restore/:step`}
          component={AdminDemoRestore}
        />
        <PrivateAdminRoute
          path={`/admin/demotools/create/:step`}
          component={AdminDemoCreate}
        />
        <PrivateAdminRoute
          path={`/admin/demotools/remove/:step`}
          component={AdminDemoRemove}
        />
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/demotools/presentation/:step`}
          component={AdminDemoSlideshow}
        />
        {/* Note: Must be below anything under the demotools path so that they match fist */}
        <PrivateAdminRoute
          path={`${process.env.PUBLIC_URL}/admin/demotools`}
          component={AdminDemoSetup}
        />
        {/* End Admin Routes */}
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/resumes`}
          component={Resumes}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/resumedetails`}
          component={PublicResumeDetails}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/postjob`}
          component={PostJob}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/jobslist`}
          component={JobsList}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/savedlaterjobs`}
          component={SavedLaterJobsList}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/editjob`}
          component={EditJob}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/employer/employerjobdetails/:jobId`}
          component={EmployersJobDetails}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/employer/employerjobdetails/`}
          component={Dashboard}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/publicresumedetails/:templateId`}
          component={PublicResumeDetails}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/publicresumedetails/`}
          component={Dashboard}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/employer/resumedetails/:jinTemplateId`}
          component={ResumeDetails}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/employer/resumedetails/`}
          component={Dashboard}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/employer/reportabuse`}
          component={ReportAbuse}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/employer/messages`}
          component={Messages}
        />
        <PrivateEmployerRoute
          path={`${process.env.PUBLIC_URL}/employer/activitieslist`}
          component={ActivitiesList}
        />
        {/* Support routing */}
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/support-dashboard`}
          component={SupportDashboard}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/sponsorship`}
          component={Sponsership}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/sponsorshipdetails/:jobSeekerSponsershipRequestId`}
          component={SponsershipDetails}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/sponsorshipdetails/`}
          component={SupportDashboard}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/supportjobdetails/:jobId`}
          component={SupportJobDetails}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/supportjobdetails/`}
          component={SupportDashboard}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/jobs`}
          component={SupportJobList}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/supportConnections`}
          component={SupportConnections}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/connectionDetails`}
          component={ConnectionDetails}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/resumedetails/:jinTemplateId`}
          component={ResumeDetails}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/resumedetails/`}
          component={SupportDashboard}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/reportabuse`}
          component={ReportAbuse}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/messages`}
          component={Messages}
        />
        <PrivateSTMRoute
          path={`${process.env.PUBLIC_URL}/support/activitieslist`}
          component={ActivitiesList}
        />
        {/* Support routing end */}
        {/* seekers routing start*/}
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/seeker`}
          component={Seeker}
        />
        <PrivateNetConnRoute
          path={`${process.env.PUBLIC_URL}/network`}
          component={Network}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/jobs`}
          component={Jobs}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/appliedjobs`}
          component={AppliedJobs}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/myresumes`}
          component={ResumeUpload}
        />
        <PrivateNetConnRoute
          path={`${process.env.PUBLIC_URL}/seekersconnections`}
          component={SeekersConnections}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/jobdetails/:jobId`}
          component={SeekersJobDetails}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/jobdetails/`}
          component={Seeker}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/jintemplate/:jinTemplateId`}
          component={JINTemplate}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/jintemplate`}
          component={JINTemplate}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/resumedetails/:jinTemplateId`}
          component={ResumeDetails}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/resumedetails/`}
          component={Seeker}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/reportabuse`}
          component={ReportAbuse}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/messages`}
          component={Messages}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/jintemplateprivate/:jinTemplateId`}
          component={JINTemplatePrivate}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/jintemplateprivate`}
          component={JINTemplatePrivate}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/activitieslist`}
          component={ActivitiesList}
        />
        <PrivateJobSeekerRoute
          path={`${process.env.PUBLIC_URL}/jobseeker/sponsorship`}
          component={SponsorshipRequest}
        />
        {/* seekers routing end*/}
        {/* Group Leader routing */}
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/groupleaderdashboard`}
          component={GroupLeaderDashboard}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/jobseekers`}
          component={JobSeekers}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/supportteammembers`}
          component={SupportTeamMembers}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/organization`}
          component={Organization}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/jobseekersdetails/:jobSeekerId`}
          component={JobSeekersDetails}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/jobseekersdetails/`}
          component={GroupLeaderDashboard}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/supportteammembersdetails/:supportTeamMemberId`}
          component={SupportTeamMembersDetails}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/supportteammembersdetails/`}
          component={GroupLeaderDashboard}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/groupleaderjobs`}
          component={GroupLeaderJobs}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/groupleaderjobdetails/:jobId`}
          component={GroupLeaderJobDetails}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/groupleaderjobdetails/`}
          component={GroupLeaderDashboard}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/reportabuse`}
          component={ReportAbuseListing}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/resumedetails/:jinTemplateId`}
          component={ResumeDetails}
        />
        <PrivateAGLRoute
          path={`${process.env.PUBLIC_URL}/groupleader/resumedetails/`}
          component={GroupLeaderDashboard}
        />
        {/* Group Leader routing end */}
        {/* Account */}
        {/* <PrivateRoute
          path={`${process.env.PUBLIC_URL}/messages`}
          component={Messages}
        /> */}
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/notifications`}
          component={Notifications}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/changepassword`}
          component={ChangePassword}
        />
        <PrivateRoute
          path={`${process.env.PUBLIC_URL}/accountinformation`}
          component={AccountInformation}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home`}
          component={(props) => (
            <Home {...props} imagetoggle={this.props.imagetoggle} />
          )}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/register`}
          component={Register}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/registerd`}
          component={Registerd}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/forgotpassword/:email`}
          component={ForgotPassword}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/termsofuse`}
          component={TermOfUse}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/videodemo`}
          component={ShowVideo}
        />
      </Switch>
    );
  }
}

export default Routes;
